import { AdUnit } from '../../Entities/AdUnit.entity';

import { PATH_BASE } from '../Constants';
import { MOBILE_BANNER_SIZE } from '../UnitSizes';

export const MobileBannerUnit = {
  path: `${PATH_BASE}/Banner_Mobile_Floating`,
  sizes: [MOBILE_BANNER_SIZE],
  defaultHeight: MOBILE_BANNER_SIZE[1],
  minWidth: 0,
  maxWidth: 776,
  minHeight: 50,
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'Mobile Banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'Mobile Banner is loaded',
    },
  },
} as const satisfies AdUnit;
