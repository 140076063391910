import { FiltersProps } from '../../Entities/Search/SearchFilters/LocationFilter/LocationFilter.entity';

const LOCATION_FILTER_KEYS = ['postcode', 'lat', 'lon'];
const REGION_FILTER_KEYS = ['nuts-label', 'nuts1-code'];

const POSTCODE_KEY = 'postcode';
const REGION_KEY = 'region';
const FILTERS_PROPS: FiltersProps = [
  {
    key: 'nuts-label',
    isMainFilterKey: true,
    filterType: REGION_KEY,
    restrictLocales: ['fr'],
  },
  {
    key: 'nuts1-code',
    filterType: REGION_KEY,
    isMainFilterKey: false,
    restrictLocales: ['fr'],
  },
  {
    key: 'lat',
    isMainFilterKey: false,
    filterType: POSTCODE_KEY,
  },
  {
    key: 'lon',
    isMainFilterKey: false,
    filterType: POSTCODE_KEY,
  },
  {
    key: POSTCODE_KEY,
    isMainFilterKey: true,
    filterType: POSTCODE_KEY,
  },
  {
    key: 'max-distance',
    isMainFilterKey: false,
    filterType: POSTCODE_KEY,
  },
];

const DEFAULT_DISTANCE = {
  uk: 60,
  fr: 60,
  de: 85,
};

export {
  DEFAULT_DISTANCE,
  REGION_KEY,
  POSTCODE_KEY,
  LOCATION_FILTER_KEYS,
  REGION_FILTER_KEYS,
  FILTERS_PROPS,
};
