import { DE_LOCALE, FR_LOCALE } from '../../Constants/Locale.constants';
import { Locale } from '../../Entities/Locale/Locale.entity';
import {
  deI15SearchApiURL,
  frI15SearchApiURL,
  ukI15SearchApiURL,
} from '../../Environment/Variables';

export const getMarketSpecificUrl = (locale?: Locale) => {
  switch (locale) {
    case FR_LOCALE:
      return frI15SearchApiURL || 'https://api.fr.staging.group-mobility-trader.de';
    case DE_LOCALE:
      return deI15SearchApiURL || 'https://api.de.staging.group-mobility-trader.de';
    default:
      return ukI15SearchApiURL || 'https://api.uk.prod.group-mobility-trader.com';
  }
};
