import { QueryParams } from '../../../Entities/Search/QueryParams.entity';

const createSearchParams = (params: QueryParams | object) => {
  return new URLSearchParams(
    Object.entries(params).flatMap(([key, values]) =>
      Array.isArray(values) ? values.map(value => [key, value]) : [[key, values]],
    ),
  );
};

const stringifyParams = (params: QueryParams) => createSearchParams(params).toString();

export { createSearchParams, stringifyParams };
