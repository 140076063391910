import { atom } from 'jotai';

import { AggregationsState } from '../../Entities/Search/Aggregations/Aggregations.entity';

export const initialAggregationsState: AggregationsState = {
  error: false,
  isLoading: false,
  'stock-condition': {},
  price: {},
  'monthly-price': {},
  makes: [],
  models: [],
  variants: [],
  'fuel-type': {},
  'body-type': {},
  color: {},
  'gear-box': {},
  doors: {},
  seats: {},
  totalCount: 0,
  'finance-product': {},
};

export const aggregationsAtom = atom<AggregationsState>({
  ...initialAggregationsState,
});
