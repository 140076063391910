const FINANCE_PRODUCTS_UK = {
  pcp: {
    id: 'pcp',
    label: 'PCP',
    selected: false,
  },
  hp: {
    id: 'hp',
    label: 'HP',
    selected: false,
  },
  ctp: {
    id: 'ctp',
    label: 'Leasing',
    selected: false,
  },
};

const FINANCE_PRODUCTS_FR = {
  privateLeasing: {
    id: 'privateLeasing',
    label: 'Leasing (LOA / LLD)',
    selected: false,
  },
  standardFinancing: {
    id: 'standardFinancing',
    label: 'Crédit classique',
    selected: false,
  },
};

const FINANCE_PRODUCTS_DE_FINANCING_KEYS = [
  'standardFinancing',
  'twoWayFinancing',
  'threeWayFinancing',
];

const FINANCE_PRODUCTS_DE = {
  privateLeasing: {
    id: 'privateLeasing',
    label: 'Leasen',
    selected: false,
  },
  financing: {
    id: FINANCE_PRODUCTS_DE_FINANCING_KEYS,
    label: 'Finanzierung',
    selected: false,
  },
};

export {
  FINANCE_PRODUCTS_FR,
  FINANCE_PRODUCTS_UK,
  FINANCE_PRODUCTS_DE,
  FINANCE_PRODUCTS_DE_FINANCING_KEYS,
};
