import { AdUnit } from '../../Entities/AdUnit.entity';

import { PATH_BASE } from '../Constants';
import { DMPU_SIZE, MPU_SIZE } from '../UnitSizes';

export const MobileTabletDMPU2 = {
  path: `${PATH_BASE}/DMPU2_UK`,
  sizes: [DMPU_SIZE, MPU_SIZE],
  defaultHeight: DMPU_SIZE[1],
  minHeight: 250,
  responsiveSizeMap: {
    1440: [],
    1280: [],
    1024: [],
    768: [DMPU_SIZE, MPU_SIZE],
    0: [DMPU_SIZE, MPU_SIZE],
  },
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'DMPU 2 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'DMPU 2 UK banner is loaded',
    },
  },
} as const satisfies AdUnit;
