'use client';
import { useAtom } from 'jotai';
import { unwrap, atomWithRefresh } from 'jotai/utils';

import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { CustomError, fetchJson } from '@core/Utils/Fetch/FetchJson';
import { camelizeObject, DeepSnakeToCamelCase } from '@core/Utils/Object/CamelizeObject';

import { serverLog } from '@core/Utils/Server/ServerLog';

import { authTokenAtom } from '../Atoms/AuthToken.atom';

interface FetchOptions {
  url: string;
}

interface ApiResponse<T> {
  data: T | DeepSnakeToCamelCase<Awaited<T>> | null;
  error: CustomError | null;
}
export function createApiDataAtom<T>({ url }: FetchOptions) {
  const asyncAtom = atomWithRefresh(async (get): Promise<ApiResponse<T>> => {
    const authToken = get(authTokenAtom);
    const locale = get(localeAtom);

    if (!authToken) {
      return { data: null, error: null };
    }

    try {
      const response = await fetchJson<T>(url, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${authToken}`,
          'x-heycar-tenant': locale,
          'x-heycar-site': 'heycar',
        },
      });

      if (typeof response === 'object') {
        return { data: camelizeObject(response), error: null };
      }
      return {
        data: null,
        error: new Error('Invalid response format') as CustomError,
      };
    } catch (error) {
      const customError = error as CustomError;

      serverLog(
        `[API error fetching data from ${url}]`,
        {
          apiUrl: url,
          errorStatus: customError?.status,
          errorText: customError?.statusText,
        },
        'error',
      );
      return { data: null, error: customError };
    }
  });

  const unwrappedAtom = unwrap(
    asyncAtom,
    (prev: ApiResponse<T> | Promise<ApiResponse<T>> | undefined) =>
      prev ?? { data: null, error: null },
  );

  return { asyncAtom, unwrappedAtom };
}

export function useApiData<T>(
  unwrappedAtom: ReturnType<typeof createApiDataAtom<T>>['unwrappedAtom'],
) {
  const [{ data, error }, refresh] = useAtom(unwrappedAtom);

  return { data, error, refresh };
}
