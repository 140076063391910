/* eslint-disable @typescript-eslint/naming-convention */
import { FINANCE_PRODUCTS_DE_FINANCING_KEYS } from '../../Constants/Filters/PriceFilter.constants';
import { DE_LOCALE } from '../../Constants/Locale.constants';
import { Locale } from '../../Entities/Locale/Locale.entity';
import { MultiSelectFilterKey } from '../../Entities/Search/QueryParams.entity';

const currentYear = new Date().getFullYear();

const AGGREGATION_FILTERS = [
  'stock-condition',
  'price',
  'monthly-price',
  'make',
  'model',
  'fuel-type',
  'body-type',
  'color',
  'gear-box',
  'doors',
  'seats',
  'finance-product',
  'engine-size',
  'eligible-products',
  'fuel-consumption',
];

const RANGE_FILTERS = {
  badge_engine_cc: [1, 1.2, 1.4, 1.6, 1.8, 2, 2.5, 3],
  monthly_price: [
    '0',
    '50',
    '100',
    '150',
    '200',
    '250',
    '300',
    '350',
    '400',
    '450',
    '500',
    '550',
    '600',
    '650',
    '700',
    '750',
    '800',
    '850',
    '900',
    '950',
    '1000',
    '1100',
    '1200',
    '1300',
    '1400',
    '1500',
    '2000',
    '3000',
    '4000',
    '5000',
  ],
  price: [
    '0',
    '1000',
    '2000',
    '3000',
    '5000',
    '7000',
    '10000',
    '15000',
    '20000',
    '25000',
    '30000',
    '35000',
    '40000',
    '50000',
    '60000',
    '80000',
    '100000',
    '120000',
    '150000',
    '175000',
    '200000',
    '300000',
    '500000',
    '1000000',
    '2000000',
  ],
  year: new Array(9).fill(null).map((_, i) => currentYear - 8 + i),
};

const SINGLE_SELECT_FILTERS = {
  bhp__range: [
    {
      id: '0__60',
      name: 'Up to 60',
    },
    {
      id: '60__110',
      name: '60 - 110',
    },
    {
      id: '110__150',
      name: '110 - 150',
    },
    {
      id: '150__300',
      name: '150 - 300',
    },
    {
      id: '300__1000',
      name: 'Over 300',
    },
  ],
  doors: [
    {
      id: '2',
      name: '2 doors',
    },
    {
      id: '3',
      name: '3 doors',
    },
    {
      id: '4',
      name: '4 doors',
    },
    {
      id: '5',
      name: '5 doors',
    },
  ],
  benefits: [
    {
      id: 'manufacturer',
      name: 'Approved cars only',
    },
  ],
  maxDistance: [
    {
      id: '5',
      name: 'less than 5 miles away',
    },
    {
      id: '10',
      name: 'less than 10 miles away',
    },
    {
      id: '25',
      name: 'less than 25 miles away',
    },
    {
      id: '50',
      name: 'less than 50 miles away',
    },
    {
      id: '75',
      name: 'less than 75 miles away',
    },
    {
      id: '100',
      name: 'less than 100 miles away',
    },
  ],
  battery_range: [
    {
      id: '50',
      name: '50 miles +',
    },
    {
      id: '100',
      name: '100 miles +',
    },
    {
      id: '150',
      name: '150 miles +',
    },
    {
      id: '200',
      name: '200 miles +',
    },
    {
      id: '250',
      name: '250 miles +',
    },
    {
      id: '300',
      name: '300 miles +',
    },
  ],
  battery_charge_slow: [
    {
      id: '3',
      name: 'Up to 3 hours',
    },
    {
      id: '6',
      name: 'Up to 6 hours',
    },
    {
      id: '9',
      name: 'Up to 9 hours',
    },
    {
      id: '12',
      name: 'Up to 12 hours',
    },
    {
      id: '15',
      name: 'Up to 15 hours',
    },
    {
      id: '20',
      name: 'Up to 20 hours',
    },
    {
      id: '100',
      name: '20 hours +',
    },
  ],
  mileage__lte: [
    {
      id: '2500',
      name: 'Below 2,500',
    },
    {
      id: '5000',
      name: 'Below 5,000',
    },
    {
      id: '10000',
      name: 'Below 10,000',
    },
    {
      id: '20000',
      name: 'Below 20,000',
    },
    {
      id: '40000',
      name: 'Below 40,000',
    },
    {
      id: '60000',
      name: 'Below 60,000',
    },
    {
      id: '80000',
      name: 'Below 80,000',
    },
  ],
  range__lte: [
    {
      id: '2500',
      name: 'Below 2,500',
    },
    {
      id: '5000',
      name: 'Below 5,000',
    },
    {
      id: '10000',
      name: 'Below 10,000',
    },
    {
      id: '20000',
      name: 'Below 20,000',
    },
    {
      id: '40000',
      name: 'Below 40,000',
    },
    {
      id: '60000',
      name: 'Below 60,000',
    },
    {
      id: '80000',
      name: 'Below 80,000',
    },
  ],
  stock_condition: [
    {
      id: 'used',
      name: 'Used cars',
    },
    {
      id: 'new',
      name: 'New cars',
    },
  ],
} as const;

const MULTISELECT_FILTERS_KEY: MultiSelectFilterKey[] = [
  'body-type',
  'color',
  'doors',
  'fuel-type',
  'gear-box',
  'make',
  'model',
  'variant-type',
  'seats',
];

const MULTISELECT_FILTERS = {
  'body-type': [
    {
      id: 'convertible',
      name: 'Convertible',
    },
    {
      id: 'coupe',
      name: 'Coupe',
    },
    {
      id: 'estate',
      name: 'Estate',
    },
    {
      id: 'hatchback',
      name: 'Hatchback',
    },
    {
      id: 'pickup',
      name: 'Pick up',
    },
    {
      id: 'mpv',
      name: 'MPV',
    },
    {
      id: 'saloon',
      name: 'Saloon',
    },
    {
      id: 'suv',
      name: 'SUV',
    },
    {
      id: 'van',
      name: 'Van',
    },
  ],
  color: [
    {
      id: 'beige',
      name: 'Beige',
    },
    {
      id: 'black',
      name: 'Black',
    },
    {
      id: 'blue',
      name: 'Blue',
    },
    {
      id: 'brown',
      name: 'Brown',
    },
    {
      id: 'grey',
      name: 'Grey',
    },
    {
      id: 'green',
      name: 'Green',
    },
    {
      id: 'orange',
      name: 'Orange',
    },
    {
      id: 'red',
      name: 'Red',
    },
    {
      id: 'silver',
      name: 'Silver',
    },
    {
      id: 'white',
      name: 'White',
    },
    {
      id: 'yellow',
      name: 'Yellow',
    },
  ],
  fuelType: [
    {
      id: 'diesel',
      name: 'Diesel',
    },
    {
      id: 'petrol',
      name: 'Petrol',
    },
    {
      id: 'hybrid',
      name: 'Hybrid',
    },
    {
      id: 'electric',
      name: 'Electric',
    },
  ],
  gearBox: [
    {
      id: 'automatic',
      name: 'Automatic',
    },
    {
      id: 'manual',
      name: 'Manual',
    },
    {
      id: 'semiauto',
      name: 'Semi-Automatic',
    },
  ],
  'variant-type': [],
} as const;

const MULTIPLE_FILTER_VALUES_CONFIG = (locale: Locale) => ({
  'finance-product': {
    filterValues: locale === DE_LOCALE ? FINANCE_PRODUCTS_DE_FINANCING_KEYS : [],
  },
  doors: {
    filterValues:
      locale === DE_LOCALE
        ? [
            ['2', '3'], // Group 1
            ['4', '5'], // Group 2
            ['6', '7'], // Group 3
          ]
        : [],
  },
});

export {
  AGGREGATION_FILTERS,
  MULTISELECT_FILTERS,
  MULTISELECT_FILTERS_KEY,
  RANGE_FILTERS,
  SINGLE_SELECT_FILTERS,
  MULTIPLE_FILTER_VALUES_CONFIG,
};
