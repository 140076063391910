import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';

import { ctaClickedToEvent } from './Schemas/CtaClicked/CtaClickedToEvent';
import { marketingToEvent } from './Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from './Schemas/UserContext/UserContextToEvent';

export interface CtaClick {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  ctaLocation?: string;
  ctaType: string;
  ctaWording: string;
  ctaUrl: string;
}

export function generateCtaClickedTracking({
  searchParams,
  userId,
  formState,
  ctaLocation,
  ctaType,
  ctaWording,
  ctaUrl,
}: CtaClick) {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: { ...formState },
  };
  const ctaEventObj = {
    ctaLocation,
    ctaType,
    ctaWording,
    targetUrl: ctaUrl,
  };

  // event actions
  const ctaEvent = ctaClickedToEvent(ctaEventObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const marketingEvent = marketingToEvent(marketingDataObj);

  const context = [userEvent, marketingEvent];

  return { event: ctaEvent, context };
}
