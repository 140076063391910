import React from 'react';

interface LocaleFilterProps {
  locale: string;
  excludes?: string[];
  includes?: string[];
  children: React.ReactNode;
}

export const LocaleFilter: React.FC<LocaleFilterProps> = ({
  locale,
  excludes,
  includes,
  children,
}) => {
  if (excludes?.includes(locale)) return null;
  if (includes && !includes.includes(locale)) return null;

  return <>{children}</>;
};
