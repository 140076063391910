import { Tenant } from '../../../Entities/App/Tenants.entity';

import {
  QueryParams,
  FilterKey,
  FilterParams,
  UrlParams,
} from '../../../Entities/Search/QueryParams.entity';

import { stringifyParams } from './queryParamUtils';

export const mapQueryParams = (params: UrlParams, tenant?: Tenant) => {
  const filterParams = {} as FilterParams;
  for (const key in params) {
    const newKey = key.replace('[]', '') as FilterKey;

    filterParams[newKey] =
      tenant === 'rac' && params[key] === 'relevance-desc'
        ? 'rac-relevance-desc'
        : (params[key] as any);
  }

  if (tenant === 'rac') filterParams['apply-exclusions'] = 'rac';

  let mappedParams: QueryParams | object = {};
  mappedParams = {
    ...filterParams,
    ...{
      page:
        filterParams?.page && parseInt(filterParams?.page) > 0
          ? parseInt(filterParams?.page) - 1
          : filterParams.page || 0,
    },
  };
  const queryString = stringifyParams(mappedParams);

  return { mappedParams: mappedParams as FilterParams, queryString };
};
