/* eslint-disable @typescript-eslint/naming-convention */
import {
  No1280BillboardUnit1,
  No1280BillboardUnit2,
  BillboardUnit1,
  BillboardUnit2,
  BillboardUnit3,
} from './Units/BillboardUnit';
import { DesktopDMPU1, DesktopDMPU2, DMPU, TabLDesktopDMPU2 } from './Units/DMPU';
import { DesktopSMMPU2 } from './Units/DesktopSMMPU2';
import { HomepageUnit } from './Units/HomepageUnit';
import { LeaderboardUnit } from './Units/LeaderboardUnit';
import { MPU } from './Units/MPU';
import { MobileBannerUnit } from './Units/MobileBannerUnit';
import { MobileDMPU, MobileDMPU2, MobileDMPU3 } from './Units/MobileDMPU';
import { MobileTabletDMPU2 } from './Units/MobileTabletDMPU2';
import { PdpMainBillboardUnit } from './Units/PdpMainBillboardUnit';
import { PdpMainMPU } from './Units/PdpMainMPU';
import { PlpDMPU1 } from './Units/PlpDMPU1';
import { ReviewDMPU1 } from './Units/ReviewDMPU1';
import { ReviewDMPU2 } from './Units/ReviewDMPU2';
import { ReviewMobileDMPU1 } from './Units/ReviewMobileDMPU1';
import { ReviewsLandingPageDMPU1 } from './Units/ReviewsLandingPageDMPU1';

export const adUnits = {
  homepageHero: HomepageUnit,

  plpBillboard1: No1280BillboardUnit1,
  plpBillboard2: No1280BillboardUnit2,
  plpDmpu1: PlpDMPU1,
  plpDmpu2: TabLDesktopDMPU2,
  plpLeaderboard: LeaderboardUnit,
  plpMobileBanner: MobileBannerUnit,
  plpMobileDmpu1: MobileDMPU,
  plpMobileDmpu2: MobileDMPU2,

  pdpMainMpu: PdpMainMPU,
  pdpMainBillboard: PdpMainBillboardUnit,
  pdpSecondaryMpu: DesktopSMMPU2,
  pdpSecondaryBillboard: No1280BillboardUnit2,
  pdpMobileDmpu: MobileTabletDMPU2,
  pdpDmpu1: DMPU,
  pdpDmpu2: TabLDesktopDMPU2,
  pdpLeaderboard: LeaderboardUnit,
  pdpMobileBanner: MobileBannerUnit,

  reviewBillboard1: BillboardUnit1,
  reviewMobileBanner: MobileBannerUnit,
  reviewMobileDmpu1: ReviewMobileDMPU1,
  reviewDmpu1: ReviewDMPU1,
  reviewDmpu2: ReviewDMPU2,

  reviewsLandingPageMobileMPU: MPU,
  reviewsLandingPageFloor: MobileBannerUnit,
  reviewsLandingLeaderboard: LeaderboardUnit,
  reviewsLandingPageBanner: BillboardUnit1,
  reviewsLandingPageDMPU1: ReviewsLandingPageDMPU1,

  nGMobileBanner: MobileBannerUnit,
  nGMobileDMPU1: MobileDMPU,
  nGMobileDMPU2: MobileDMPU2,
  nGMobileDMPU3: MobileDMPU3,
  nGDesktopBB: BillboardUnit1,
  nGDesktopBB2: BillboardUnit2,
  nGDesktopBB3: BillboardUnit3,
  nGDesktopDMPU1: DesktopDMPU1,
  nGDesktopDMPU2: DesktopDMPU2,
} as const;

export type AdUnitId = keyof typeof adUnits;
