import { useAtomValue } from 'jotai';

import { LoadingBackground } from '@core/Components/LoadingBackground/LoadingBackground';
import { Error } from '@gds/Icons/Paths/Error';
import { SuccessTickCircle } from '@gds/Icons/Paths/SuccesTickCircle';
import { Typography } from '@gds/Typography/Typography';
import { hasKwankoCookieApprovedAtom } from '@tracking/Atoms/HasKwankoCookieApproved.atom';

import styles from './StatusDisplay.module.css';

export interface StatusDisplayProps {
  kwankoListingId?: string;
  kwankoTrackingId?: string;
  leftAlignText?: boolean;
  messages: Record<string, string>;
  type: 'error' | 'success' | 'loading';
}

export const StatusDisplay = ({
  messages,
  type,
  leftAlignText,
  kwankoListingId,
  kwankoTrackingId,
}: StatusDisplayProps) => {
  const Icon = type === 'success' ? SuccessTickCircle : Error;

  const hasKwankoCookie = useAtomValue(hasKwankoCookieApprovedAtom);

  if (hasKwankoCookie && window.KWKUniJS !== undefined && kwankoTrackingId && kwankoListingId) {
    window.KWKUniJS.conversion({
      mclic: `${kwankoTrackingId || ''}`,
      argann: `${kwankoListingId || ''}`,
    });
    window.KWKUniJS.ptag({
      zone: 'lead_confirmation',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      id_lead: `${kwankoListingId || ''}`,
    });
  }

  return (
    <>
      <div className={styles.resultMessageWrapper}>
        {type === 'loading' ? <LoadingBackground /> : <Icon fontSize={64} />}
        <Typography Component="p" variant="h5" className={styles.resultHeader}>
          {messages.headline}
        </Typography>
      </div>
      {messages.subheading && (
        <Typography
          variant="body1"
          className={`${styles.resultSubhead} ${leftAlignText ? styles.leftAlign : ''}`}
        >
          {messages.subheading}
        </Typography>
      )}
      {messages.description && (
        <Typography
          variant="body3"
          className={`${styles.resultDescription} ${leftAlignText ? styles.leftAlign : ''}`}
        >
          {messages.description}
        </Typography>
      )}
    </>
  );
};
