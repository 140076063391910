import { AdUnit } from '../../Entities/AdUnit.entity';
import { PATH_BASE } from '../Constants';
import { HOMEPAGE_MOBILE_SIZE, HOMEPAGE_DESKTOP_SIZE } from '../UnitSizes';

const unitIt =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' || process.env.NEXT_PUBLIC_APP_ENV === 'staging'
    ? 'Homepage1_UK'
    : 'devonly-Homepage1_UK';

export const HomepageUnit = {
  path: `${PATH_BASE}/${unitIt}`,
  sizes: [HOMEPAGE_MOBILE_SIZE, HOMEPAGE_DESKTOP_SIZE],
  minWidth: 320,
  defaultWidth: HOMEPAGE_MOBILE_SIZE[0],
  defaultHeight: HOMEPAGE_MOBILE_SIZE[1],
  responsiveSizeMap: {
    1440: [HOMEPAGE_DESKTOP_SIZE],
    1280: [HOMEPAGE_DESKTOP_SIZE],
    1024: [HOMEPAGE_DESKTOP_SIZE],
    768: [HOMEPAGE_DESKTOP_SIZE],
    0: [HOMEPAGE_MOBILE_SIZE],
  },
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'UK webapp Homepage 1 banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'UK webapp Homepage 1 banner is viewable',
    },
  },
} as const satisfies AdUnit;
