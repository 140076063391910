import { atomWithStorage } from 'jotai/utils';

import { DEFAULT_LOCALE, FR_LOCALE, DE_LOCALE } from '../../Constants/Locale.constants';
import { createCookieStorage } from '../../Utils/CookieStorage';

export type PostcodeAtomType = {
  [key: string]: string;
};

const defaultValue = {
  [DEFAULT_LOCALE]: '',
  [FR_LOCALE]: '',
  [DE_LOCALE]: '',
};

export const postcodeAtom = atomWithStorage<PostcodeAtomType>(
  'postcodeValue',
  defaultValue,
  createCookieStorage<PostcodeAtomType>(defaultValue),
);
