import { AdUnit } from '../../Entities/AdUnit.entity';

import { PATH_BASE } from '../Constants';
import { DMPU_SIZE, MPU_SIZE } from '../UnitSizes';

const common = {
  path: `${PATH_BASE}/DMPU1_UK`,
  sizes: [DMPU_SIZE, MPU_SIZE],
  defaultHeight: DMPU_SIZE[1],
  minHeight: 250,
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'DMPU 1 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'DMPU 1 UK banner is loaded',
    },
  },
};

const common2 = {
  ...common,
  path: `${PATH_BASE}/DMPU2_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'DMPU 2 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'DMPU 2 UK banner is loaded',
    },
  },
};

export const DMPU = {
  ...common,
  responsiveSizeMap: {
    1440: [DMPU_SIZE, MPU_SIZE],
    1280: [DMPU_SIZE, MPU_SIZE],
    1024: [DMPU_SIZE, MPU_SIZE],
    768: [MPU_SIZE],
    0: [MPU_SIZE],
  },
} as const satisfies AdUnit;

export const DesktopDMPU1 = {
  ...common,
  responsiveSizeMap: {
    1440: [DMPU_SIZE, MPU_SIZE],
    1280: [DMPU_SIZE, MPU_SIZE],
    1024: [],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;

export const DesktopDMPU2 = {
  ...common2,
  responsiveSizeMap: {
    1440: [DMPU_SIZE, MPU_SIZE],
    1280: [DMPU_SIZE, MPU_SIZE],
    1024: [],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;

export const TabLDesktopDMPU2 = {
  ...common2,
  responsiveSizeMap: {
    1440: [DMPU_SIZE, MPU_SIZE],
    1280: [DMPU_SIZE, MPU_SIZE],
    1024: [DMPU_SIZE, MPU_SIZE],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;
