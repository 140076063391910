import { AdUnit } from '../../Entities/AdUnit.entity';

import { PATH_BASE } from '../Constants';
import { DMPU_SIZE, MPU_SIZE } from '../UnitSizes';

const common = {
  sizes: [DMPU_SIZE, MPU_SIZE],
  defaultHeight: DMPU_SIZE[1],
  minHeight: 250,
  responsiveSizeMap: {
    1440: [],
    1280: [],
    1024: [],
    768: [],
    0: [DMPU_SIZE, MPU_SIZE],
  },
  useSizeMap: true,
};

export const MobileDMPU = {
  ...common,
  path: `${PATH_BASE}/DMPU1_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'DMPU 1 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'DMPU 1 UK banner is loaded',
    },
  },
} as const satisfies AdUnit;

export const MobileDMPU2 = {
  ...common,
  path: `${PATH_BASE}/DMPU2_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'DMPU 2 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'DMPU 2 UK banner is loaded',
    },
  },
} as const satisfies AdUnit;

export const MobileDMPU3 = {
  ...common,
  path: `${PATH_BASE}/DMPU3_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'DMPU 3 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'DMPU 3 UK banner is loaded',
    },
  },
} as const satisfies AdUnit;
