'use client';

import { useSetAtom } from 'jotai';

import { closeModalAtom, openModalAtom, resetModalAtom } from '../Atoms/Modal.atom';

export const useModal = () => {
  const openModal = useSetAtom(openModalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const resetModal = useSetAtom(resetModalAtom);

  return { openModal, closeModal, resetModal };
};
